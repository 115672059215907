<template>
  <div>
    <div class="mx-0 fs-12 d-custom-flex mr-3 mb-5">
      <span class="ml-2" :style="{color: strategyColors[strategy]}">
        <h4>{{ strategy }}</h4>
      </span>
      <v-spacer></v-spacer>
      <span>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('highcharts.renkoChart.downloadData')" @click="renkoDataExportor">mdi-download</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('highcharts.renkoChart.snapshotAndShare')" @click="generateImage">mdi-camera</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.refresh')" @click="getRenkoData">mdi-refresh</v-icon>
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
      </span>
    </div>
    <div ref="renkoImage" :id="strategy" class="mr-3" style="height: 340px;"></div>
    <!-- 上传截图弹窗 -->
    <v-dialog v-model="renkoImageDialog" persistent width="450">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("highcharts.renkoChart.imageURL")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="renkoImageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div v-show="!uploadBase64Loading" class="mt-3">
              <v-text-field id="imageHttpUrl" v-model="imageHttpUrl" single-line outlined disabled hide-details class="mt-3 mb-3"></v-text-field>
              <v-btn text small class="text-none lineblue--text pl-0" @click="saveImage()">{{$t("common.save")}}</v-btn>
              <v-btn text small class="text-none lineblue--text" v-clipboard:copy="imageHttpUrl" v-clipboard:success="copySuccess" v-clipboard:error="copyError">{{$t("common.copy")}}</v-btn>
              <v-btn text small class="text-none lineblue--text" :href="imageHttpUrl" target="_black">{{$t("common.open")}}</v-btn>
            </div>
            <div v-show="uploadBase64Loading" class="mt-3 text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- 开始交易弹窗 -->
      <v-dialog v-model="startTradingDialog" hide-overlay width="450">
          <v-card>
            <v-card-title class="grey lighten-2">
              <span class="black--text">{{ $t('highcharts.strategyRenkoChart.startTrading') }}</span>
              <v-spacer></v-spacer>
              <v-btn light icon @click="startTradingDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-container class="pb-3">
              <div>
                <h4>
                  <span :style="{color: strategyColors[orderParam.strategy]}">{{ orderParam.strategy }}</span>
                  <span class="ml-5">{{ orderParam.price }}</span>
                </h4>
              </div>
              <v-btn block tile depressed class="text-none mt-3" @click="startTrading('futures')">{{ $t('menu.futures') }}</v-btn>
              <v-btn block tile depressed class="text-none mt-2" @click="startTrading('swapUsdt')">{{ $t('menu.swapUsdt') }}</v-btn>
            </v-container>
          </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<style>
  svg.highcharts-root{
    font-family: "Lexend Deca", sans-serif!important;
  }
</style>
<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import Bus from "@/components/bus/Bus";
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  import CsvExportor from 'csv-exportor';
  import html2canvas from 'html2canvas';
  export default {
    data() {
      return {
        domainName: config.domainName,
        // Renko策略数组
        renkoStrategies: [],
        // 数据数组
        seriesData: [],
        // 导出的数据数组
        exportorSeriesData: [],
        // 实时的数据数组
        realtimeSeriesData: [],
        // 交易对
        renkoSymbol: null,
        // Renko图片弹窗
        renkoImageDialog: false,
        // Renko图片base64地址
        imageBase64Url: null,
        // Renko图片http地址
        imageHttpUrl: null,
        // 上传图片加载中
        uploadBase64Loading: false,
        // 图表id
        // id: 'renkoChart',
        // 生成的整个图表对象
        chart: null,
        // 图表加载中
        chartLoading: true,
        // Renko配置
        renkoConfig: {
          trend: null,
          size: 0,
          open: 0,
          close: 0
        },
        // 当前价
        currentPrice: 0,
        // 开始交易弹窗
        startTradingDialog: false,
        // 下单参数 
        orderParam:{
          side: null,
          strategy: null,
          price: 0
        },
        // 数据选项
        option:{
          colors: ['#95CEFF'],
          // 图表类型
          chart: {
            backgroundColor: this.darkMode ? 'dark' : '',
            type: 'column',
          },
          // 范围选择器
          rangeSelector: {
            enabled: false,
            allButtonsEnabled: true,
            inputEnabled: false,
            buttonPosition: {
              x: 0
            },
            // "millisecond", "second", "minute", "hour", "day", "week", "month", "ytd", "all"
            buttons: [{
              type: 'all',
              text: 'All'
            }],
            buttonTheme: {
              fill: "#eee",
              stroke: "none",
              r: 0,
              style: {
                  color: "#666",
                  fill: "#2196f3"
              },
              states: {
                hover: {
                    fill: "#eee",
                },
                select: {
                  fill: "#eee",
                  style: {
                    color: "#2196f3"
                  }
                }
              },
            },
            selected: 0
          },
          // 标题
          title: {
            text: null
          },
          // 图例导航
          legend: {
            enabled: false
          },
          // X轴
          xAxis: [{
            // visible: false
            tickInterval: 4,
            crosshair: {
              dashStyle: 'shortdash',
              // 是否与最近的点对齐，为 true 时十字准星线会自动的对齐到最近的点，为 false 则是当前鼠标的位置
              snap: true,
              width: 1,
              label: {
                enabled: true,
                backgroundColor: '#FEE108',
                style:{
                	color:'#212121',
                },
                format: '{value:%m/%d %H:%M}'
              }
            },
            tickLength: 10,
            tickWidth: 1,
            tickPosition: 'inside',
            type: 'datetime',
            dateTimeLabelFormats: {
              millisecond: '%m/%d',
              second: '%m/%d',
              minute: '%m/%d',
              hour: '%m/%d',
              day: '%m/%d',
              week: '%m/%d',
              month: '%m/%d',
              year: '%Y'
            }
          }],
          // y轴
          yAxis: [{
            title: {
              text: null
            },
            gridLineWidth: 0,
            opposite: true,
            crosshair: {
              dashStyle: 'shortdash',
              // 是否与最近的点对齐，为 true 时十字准星线会自动的对齐到最近的点，为 false 则是当前鼠标的位置
              snap: true,
              width: 1,
              label: {
                enabled: true,
                backgroundColor: '#FEE108',
                style:{
                	color:'#212121',
                },
                format: '{value:.2f}'
              }
            },
            labels: {
              align: "left",
              x: 10
            },
            plotLines: [{
              value: null,
              color: '#9e9e9e',
              dashStyle: 'shortdash',
              width: 1
              // label: {
              //   text: null,
              //   useHTML: true,
              //   align: 'right',
              //   x: 50,
              //   style: {
              //     backgroundColor: 'red'
              //   }
              // },
              // zIndex: 5
            }]
          }],
          // 导航器
          navigator: {
            enabled: false,
            series: {
              color: '',
              // 线宽0
              lineWidth: 0,
              // 透明度0
              fillOpacity: 0
            },
            // 格式化时间选择器的时间格式
            xAxis: {
              labels: {
                format: '{value: %m/%d/%Y}'
              }
            }
          },
          // 鼠标悬浮提示框
          tooltip: {
            split: false,
            useHTML: true,
            hideDelay: 0,
            borderColor:'black',
            headerFormat: '',
            pointFormat:'<b>{series.name}</b><br/><h6></h6>'+
                        '<b>'+ this.$t('highcharts.renkoChart.open') +': </b>{point.open}<br/>'+
                        '<b>'+ this.$t('highcharts.renkoChart.high') +': </b>{point.high}<br/>'+
                        '<b>'+ this.$t('highcharts.renkoChart.low') +': </b>{point.low}<br/>'+
                        '<b>'+ this.$t('highcharts.renkoChart.close') +': </b>{point.close}<br/>',
            footerFormat: '<b>'+ this.$t('highcharts.renkoChart.time') + ': </b>{point.key}',
            shape: 'square',
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M',
              second: '%m/%d/%Y %H:%M',
              minute: '%m/%d/%Y %H:%M',
              hour: '%m/%d/%Y %H:%M',
              day: '%m/%d',
              week: '%m/%d',
              month: '%m/%Y',
              year: '%Y'
            },
            // 提示框固定位置
            positioner: function() {
              return {
                x: 10,
                y: 10
              }
            }
          },
          // 关闭滑块
          scrollbar: {
            enabled : false
          },
          // 数据块的设置
          plotOptions: {
            candlestick: {
              cursor: 'pointer',
              pointPadding: -0.3,
              events: { },
              dataLabels:{
                allowOverlap:true,
                enabled: true,
                style: {
                  textOutline: '0'
                },
                formatter:function(){
                  if(this.y == this.series.dataMax){
                    return this.y;
                  }
                  if(this.y == this.series.dataMin) {
                    return this.y;
                  }
                }
              }
            }
          },
          // 数据
          series: [{
            id: 'dataseries',
            name: '',
            type: 'candlestick',
            data: [],
            tooltip: {
              valueDecimals: 2
            },
            color: '#ef5350',
            lineColor: '#ef5350',
            upColor: '#25a69a',
            upLineColor: '#25a69a'
          }],
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#9e9e9e'
            }
          },
          // 版权信息
          credits: {
            enabled: false,
            href: config.domainName,
            text: this.$t('highcharts.dataFromIOB')
          },
          // 导出模块
          exporting: {
            enabled: false,
            filename: 'Renko-Chart',
            buttons: {
              contextButton: {
                align: "right",
                verticalAlign: "top",
                x: 1,
                y: 0
              }
            }
          },
          navigation: {
            buttonOptions: {
                symbolStroke:'black',
                theme: {
                    fill: '#EEEEEE',
                }
            }
          },
          loading: {
            style: {
              backgroundColor: 'silver'
            },
            labelStyle: {
              color: 'white',
              fontSize: '36px'
            }
          }
        },
      }
    },
    props: ['strategy', 'currency', 'tradingCategory' ],
    watch: {
      // 监听交易对改变重新加载数据
      renkoSymbol(newVal, oldVal){
        this.getRenkoData();
      },
      // 监听数据改变重新渲染图表
      seriesData(newVal, oldVal){
        this.option.series[0].name = this.renkoSymbol;
        this.option.series[0].data = JSON.parse(JSON.stringify(newVal));
        let decimalNumbers = this.renkoDecimalNumbers[this.renkoSymbol];
        this.option.series[0].tooltip.valueDecimals = decimalNumbers;
        this.option.yAxis[0].labels.format = '{value:.' + decimalNumbers + 'f}';
        this.option.yAxis[0].crosshair.label.format = '{value:.' + decimalNumbers + 'f}';
        this.realtimeSeriesData = [].concat(newVal);
        if (newVal.length > 0) {
          // 数据实例: [timestamp, open, high, low, close, trend, datatime]
          let lastRenkoData = this.option.series[0].data[this.option.series[0].data.length - 1];
          // 计算Renko配置
          // this.getRenkoConfig(lastRenkoData);
          // 计算最大值和最小值
          // this.getMaxAndMin();
          // 显示横线，默认灰色
          let plotLinesColor = '#9e9e9e';
          if (lastRenkoData[1] > lastRenkoData[4]) {
            // 下跌
            plotLinesColor = this.darkMode ? '#792c38': '#ef5350';
          } else {
            // 上涨
            plotLinesColor = this.darkMode ? '#255d4b':'#25a69a';
          }
          this.option.yAxis[0].plotLines[0].value = lastRenkoData[4];
          this.option.yAxis[0].plotLines[0].color = plotLinesColor;
          this.getUnfinishedBricks(this.currentPrice);
          // this.option.yAxis[0].plotLines[0].label.text = this.option.series[0].data[this.option.series[0].data.length - 1][4];
        } else {
          this.option.yAxis[0].plotLines[0].value = null;
          this.option.yAxis[0].plotLines[0].color = null;
          // this.option.yAxis[0].plotLines[0].label.text = null;
        }
        // 重新渲染图表
        this.chart = Highcharts.stockChart(this.strategy, this.option);
      },
      // 监听实时的Renko数据渲染图表
      realtimeSeriesData(newVal, oldVal){
        // this.option.series[0].data = newVal;
        // // 重新渲染图表
        // this.chart = Highcharts.stockChart(this.strategy, this.option);
        if (this.chart.series != null && this.chart.series.length > 0 && this.chart.series[0] != null) {
          this.chart.series[0].setData(newVal, true, false, false);
        }
      },
      // 监听加载中
      chartLoading(newVal, oldVal){
        if (this.chart) {
          if (newVal) {
            this.chart.showLoading();
          } else {
            this.chart.hideLoading();
          }
        }
      },
      // 监听传来的货币
      currency(newVal, oldVal){
        if (newVal) {
          this.renkoSymbol = newVal + '-USDT';
        }
      },
      // 监听当前价
      currentPrice(newVal, oldVal){
        if (newVal > 0) {
          this.getUnfinishedBricks(newVal);
        }
      },
      //监听黑暗模式
      darkMode(){
        // 导航器按钮的颜色
        this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
        // 初始化最大值和最小值的字体颜色
        this.option.plotOptions.candlestick.dataLabels.color = this.darkMode ? '#ffffff':'#212121';
        this.getRenkoData();
      }
    },
    created(){

    },
    components: { html2canvas },
    mounted(){
      if (this.currency) {
        this.renkoSymbol = this.currency + '-USDT';
      }
      // 监听当前价的事件
      Bus.$on('emitCurrentPrice', data => {
        if (data > 0) {
            this.currentPrice = data;
        }
      })
      Highcharts.setOptions({
        global: {
          // 是否使用UTC时间
          useUTC: this.time == 'UTCTime'
        },
        lang: {
          noData: this.$t('highcharts.noData'),
          contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
          printChart: this.$t('highcharts.printChart'),
          resetZoom: this.$t('highcharts.resetZoom'),
          resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
          downloadPNG: this.$t('highcharts.downloadPNG'),
          downloadJPEG: this.$t('highcharts.downloadJPEG'),
          downloadPDF: this.$t('highcharts.downloadPDF'),
          downloadSVG: this.$t('highcharts.downloadSVG')
        }
      });
      // 初始化加载数据渲染图表
      this.initEvents();
      // 初始化最大值和最小值的字体颜色
      this.option.plotOptions.candlestick.dataLabels.color = this.darkMode ? '#ffffff':'#212121';
      this.chart = Highcharts.stockChart(this.strategy, this.option);
    },
    computed: {
      ...mapGetters(['defaultExchange', 'responsive', 'darkMode', 'time', 'renkoSymbols', 'renkoDecimalNumbers', 'marketPrice', 'strategyColors']),
      tradingSymbol(){
        let currency = this.renkoSymbol.replace('-USDT', '');
        if (this.tradingCategory == 'futures') {
          return 'PI_' + currency + 'USD';
        } else {
          return currency + '-USDT-SWAP';
        }
        return currency;
      }
    },
    methods:{
      // 查询Renko数据
      getRenkoData(){
        this.chartLoading = true;
        if (this.strategy == null || this.renkoSymbol == null) {
          return;
        }
        let api = config.baseUrl + '/renko/private/renko?strategy=' + this.strategy + '&symbol=' + this.renkoSymbol;
        this.$http.get(api).then(response => {
          let data = response.data;
          this.chartLoading = false;
          if (Object.prototype.toString.call(data) === "[object Array]") {
            // 数组
            if (data.length == 0) {
              this.seriesData = [];
              this.exportorSeriesData = [];
            } else {
              let list = data;
              if (data.length > 60) {
                list = data.slice(-60);
              }
              this.seriesData = list;
              this.exportorSeriesData = [].concat(data);
              // 计算Renko配置的size
              let lastRenkoData = data[data.length - 1];
              this.renkoConfig.size = Number(subtraction(lastRenkoData[2], lastRenkoData[3]));
              this.getRenkoConfig(lastRenkoData);
              // this.getRangeSelectorButtons();
              this.getUnfinishedBricks(this.currentPrice);
            }
          } else if (Object.prototype.toString.call(data)==='[object Object]'){
            // 对象
            this.seriesData = [];
            this.exportorSeriesData = [];
            this.$store.dispatch('snackbarMessageHandler', data.message);
          } else {
            // 啥也不是
            this.seriesData = [];
            this.exportorSeriesData = [];
          }
        }, error => {
          this.chartLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // Renko数据导出
      renkoDataExportor(){
        let heads = [this.$t('highcharts.renkoChart.timestamp'),
                    this.$t('highcharts.renkoChart.open'),
                    this.$t('highcharts.renkoChart.high'),
                    this.$t('highcharts.renkoChart.low'),
                    this.$t('highcharts.renkoChart.close'),
                    this.$t('highcharts.renkoChart.trend'),
                    this.$t('highcharts.renkoChart.time')];
        CsvExportor.downloadCsv(this.exportorSeriesData, { header: heads }, 'Renko-Data-' + this.strategy + '-' + this.renkoSymbol + '.csv');
      },
      // 初始化数据的点击事件
      initEvents(){
        let _this = this;
        let events = {
          click: function(e) {
            let data = 0;
            if (e.point.open > e.point.close) {
              // 下跌
              data = e.point.low;
              _this.orderParam.side = 'short';
            } else {
              // 上升
              data = e.point.high;
              _this.orderParam.side = 'long';
            }
            _this.$emit('transferPrice', data);
            _this.orderParam.strategy = _this.strategy;
            _this.orderParam.price = data;
            _this.startTradingDialog = true;
          }
        };
        this.option.plotOptions.candlestick.events = events;
      },
      // 格式化数字
      formatNum(num) {
        return formatNumber(num);
      },
      // 计算Renko配置
      getRenkoConfig(renkoData){
        if(renkoData == null){
          return;
        }
        if (Object.prototype.toString.call(renkoData) === "[object Object]") {
          // 数据实例: {open: 0, high: 0, low: 0, close: 0, trend: 'up'}
          let open = renkoData.open;
          let close = renkoData.close;
          this.renkoConfig.open = open;
          this.renkoConfig.close = close;
          if (this.renkoConfig.size == 0) {
            // 差值
            let difference = subtraction(open, close);
            // size = 绝对值(open - close)
            this.renkoConfig.size = Math.abs(difference);
          }
          // Renko趋势
          this.renkoConfig.trend = renkoData.trend;
        } else {
          // 数据实例: [timestamp, open, high, low, close, trend, datatime]
          let open = renkoData[1];
          let close = renkoData[4];
          this.renkoConfig.open = open;
          this.renkoConfig.close = close;
          if (this.renkoConfig.size == 0) {
            // 差值
            let difference = subtraction(open, close);
            // size = 绝对值(open - close)
            this.renkoConfig.size = Math.abs(difference);
          }
          // Renko趋势
          this.renkoConfig.trend = renkoData[5];
        }
      },
      // 获得未完成的块
      getUnfinishedBricks(currentPrice){
        if(this.chartLoading){
          // 如果数据还在加载中，不计算新块
          return;
        }
        currentPrice = Number(currentPrice);
        if (currentPrice <= 0) {
          // 先更新为历史数据
          this.realtimeSeriesData = [].concat(this.seriesData);
          return;
        }
        let currentTimestamp = +new Date();
        let size = this.renkoConfig.size;
        // 如果高度小于或等于0，不计算直接返回
        if (size <= 0) {
          // 先更新为历史数据
          this.realtimeSeriesData = [].concat(this.seriesData);
          return;
        }
        // 先更新为历史数据
        let realtimeSeriesData = [].concat(this.seriesData);
        // 历史上升
        if (this.renkoConfig.trend == 'up') {
          if (currentPrice > this.renkoConfig.close) {
            /****************历史上升，当前上升***************************/
            // 倍数
            let multiple = (currentPrice - this.renkoConfig.close) / size;
            // 整块的数量
            let brickQuantity = Math.floor(multiple);
            // 计算整块的数据
            for(let i = 0; i < brickQuantity; i++){
              let open = this.renkoConfig.close + i * size;
              let renko = { x: currentTimestamp + i, open: open, high: open + size, low: open, close: open + size, trend: 'up', color: this.darkMode ? '#255d4b': '#A9DCC3', lineColor: this.darkMode ? '#255d4b': '#A9DCC3' };
              // let renko = [currentTimestamp + i, open, open + size, open, open + size, 'up', formatISO8601Time(currentTimestamp + i)];
              realtimeSeriesData.push(renko);
            }
            // 计算最后的半个块
            if (realtimeSeriesData.length > 0) {
              let lastRenkoData = realtimeSeriesData[realtimeSeriesData.length - 1];
              let lastClose = 0;
              if (Object.prototype.toString.call(lastRenkoData) === "[object Object]") {
                lastClose = lastRenkoData.close;
              } else {
                lastClose = lastRenkoData[4];
              }
              if (currentPrice > lastClose) {
                // 如果当前价大于最后的收盘价没说明还存在半个块
                let renko = { x: currentTimestamp + brickQuantity, open: lastClose, high: currentPrice, low: lastClose, close: currentPrice, trend: 'up', color: this.darkMode ? '#255d4b':'#A9DCC3', lineColor: this.darkMode ? '#255d4b':'#A9DCC3' };
                // let renko = [currentTimestamp + brickQuantity, lastClose, currentPrice, lastClose, currentPrice, 'up', formatISO8601Time(currentTimestamp + brickQuantity)];
                realtimeSeriesData.push(renko);
              }
            }
          } else if (currentPrice < this.renkoConfig.open) {
            /*****************历史上升，当前下降**************************/
            // 倍数
            let multiple = (this.renkoConfig.open - currentPrice) / size;
            // 整块的数量
            let brickQuantity = Math.floor(multiple);
            // 计算整块的数据
            for(let i = 0; i < brickQuantity; i++){
              let open = this.renkoConfig.open - i * size;
              let renko = { x: currentTimestamp + i, open: open, high: open, low: open - size, close: open - size, trend: 'dn', color:this.darkMode ? '#792C38': '#F5A6AE', lineColor:this.darkMode ? '#792C38': '#F5A6AE' };
              // let renko = [currentTimestamp + i, open, open, open - size, open - size, 'dn', formatISO8601Time(currentTimestamp + i)];
              realtimeSeriesData.push(renko);
            }
            // 计算最后的半个块
            if (realtimeSeriesData.length > 0) {
              // 拿出最后一个块
              let lastRenkoData = realtimeSeriesData[realtimeSeriesData.length - 1];
              let lastClose = 0;
              if (Object.prototype.toString.call(lastRenkoData) === "[object Object]") {
                if (lastRenkoData.trend == 'up') {
                  lastClose = lastRenkoData.open;
                } else {
                  lastClose = lastRenkoData.close;
                }
              } else {
                if (lastRenkoData[5] == 'up') {
                  lastClose = lastRenkoData[1];
                } else {
                  lastClose = lastRenkoData[4];
                }
              }
              if (currentPrice < lastClose) {
                // 如果当前价小于最后的收盘价没说明还存在半个块
                let renko = { x: currentTimestamp + brickQuantity, open: lastClose, high: lastClose, low: currentPrice, close: currentPrice, trend: 'dn', color:this.darkMode ? '#792C38': '#F5A6AE', lineColor: this.darkMode ? '#792C38':'#F5A6AE' };
                // let renko = [currentTimestamp + brickQuantity, lastClose, lastClose, currentPrice, currentPrice, 'dn', formatISO8601Time(currentTimestamp + brickQuantity)];
                realtimeSeriesData.push(renko);
              }
            }
          }
        } else {
          // 历史下降
          if (currentPrice > this.renkoConfig.open) {
            /****************历史下降，当前上升***************************/
            // 倍数
            let multiple = (currentPrice - this.renkoConfig.open) / size;
            // 整块的数量
            let brickQuantity = Math.floor(multiple);
            // 计算整块的数据
            for(let i = 0; i < brickQuantity; i++){
              let open = this.renkoConfig.open + i * size;
              let renko = { x: currentTimestamp + i, open: open, high: open + size, low: open, close: open + size, trend: 'up', color: this.darkMode ? '#255d4b':'#A9DCC3', lineColor: this.darkMode ? '#255d4b': '#A9DCC3' };
              // let renko = [currentTimestamp + i, open, open + size, open, open + size, 'up', formatISO8601Time(currentTimestamp + i)];
              realtimeSeriesData.push(renko);
            }
            // 计算最后的半个块
            if (realtimeSeriesData.length > 0) {
              // 拿出最后一个块
              let lastRenkoData = realtimeSeriesData[realtimeSeriesData.length - 1];
              let lastClose = 0;
              if (Object.prototype.toString.call(lastRenkoData) === "[object Object]") {
                if (lastRenkoData.trend == 'dn') {
                  lastClose = lastRenkoData.open;
                } else {
                  lastClose = lastRenkoData.close;
                }
              } else {
                if (lastRenkoData[5] == 'dn') {
                  lastClose = lastRenkoData[1];
                } else {
                  lastClose = lastRenkoData[4];
                }
              }
              if (currentPrice > lastClose) {
                // 如果当前价大于最后的收盘价没说明还存在半个块
                let renko = { x: currentTimestamp + brickQuantity, open: lastClose, high: currentPrice, low: lastClose, close: currentPrice, trend: 'up', color:this.darkMode ? '#255d4b': '#A9DCC3', lineColor: this.darkMode ? '#255d4b':'#A9DCC3' };
                // let renko = [currentTimestamp + brickQuantity, lastClose, currentPrice, lastClose, currentPrice, 'up', formatISO8601Time(currentTimestamp + brickQuantity)];
                realtimeSeriesData.push(renko);
              }
            }
          } else if (currentPrice < this.renkoConfig.close) {
            /*****************历史下降，当前下降**************************/
            // 倍数
            let multiple = (this.renkoConfig.close - currentPrice) / size;
            // 整块的数量
            let brickQuantity = Math.floor(multiple);
            // 计算整块的数据
            for(let i = 0; i < brickQuantity; i++){
              let open = this.renkoConfig.close - i * size;
              let renko = { x: currentTimestamp + i, open: open, high: open, low: open - size, close: open - size, trend: 'dn', color: this.darkMode ? '#792C38':'#F5A6AE', lineColor:this.darkMode ? '#792C38': '#F5A6AE' };
              // let renko = [currentTimestamp + i, open, open, open - size, open - size, 'dn', formatISO8601Time(currentTimestamp + i)];
              realtimeSeriesData.push(renko);
            }
            // 计算最后的半个块
            if (realtimeSeriesData.length > 0) {
              // 拿出最后一个块
              let lastRenkoData = realtimeSeriesData[realtimeSeriesData.length - 1];
              let lastClose = 0;
              if (Object.prototype.toString.call(lastRenkoData) === "[object Object]") {
                lastClose = lastRenkoData.close;
              } else {
                lastClose = lastRenkoData[4];
              }
              if (currentPrice < lastClose) {
                // 如果当前价小于最后的收盘价没说明还存在半个块
                let renko = { x: currentTimestamp + brickQuantity, open: lastClose, high: lastClose, low: currentPrice, close: currentPrice, trend: 'dn', color:this.darkMode ? '#792C38': '#F5A6AE', lineColor: this.darkMode ? '#792C38': '#F5A6AE' };
                // let renko = [currentTimestamp + brickQuantity, lastClose, lastClose, currentPrice, currentPrice, 'dn', formatISO8601Time(currentTimestamp + brickQuantity)];
                realtimeSeriesData.push(renko);
              }
            }
          }
        }
        this.realtimeSeriesData = realtimeSeriesData;
      },
      // 计算最大值和最小值
      getMaxAndMin(){
        if (this.seriesData.length == 0) {
          return;
        }
        // 初始化最大值数据
        let maxRenkoData = this.seriesData[0];
        // 初始化最小值数据
        let minRenkoData = this.seriesData[0];
        let max = this.seriesData[0][2];
        let min = this.seriesData[0][3];
        let maxIndex = 0;
        let minIndex = 0;
        for(let i = 0, len = this.seriesData.length; i < len; i++){
          if (this.seriesData[i][2] > max) {
            maxRenkoData = this.seriesData[i];
            max = this.seriesData[i][2];
            maxIndex = i;
          }
          if (this.seriesData[i][3] < min) {
            minRenkoData = this.seriesData[i];
            min = this.seriesData[i][3];
            minIndex = i;
          }
        }
        let maxDataObject = {
          x: maxRenkoData[0], 
          open: maxRenkoData[1],
          high: maxRenkoData[2],
          low: maxRenkoData[3],
          close: maxRenkoData[4],
          trend: maxRenkoData[5],
          dataLabels: {
            enabled: true,
            y: -20,
            style: {
              color: this.darkMode ? '#ffffff':'#212121',
              textOutline:'0'
            }
          }
        };
        let minDataObject = {
          x: minRenkoData[0], 
          open: minRenkoData[1],
          high: minRenkoData[2],
          low: minRenkoData[3],
          close: minRenkoData[4],
          trend: minRenkoData[5],
          dataLabels: {
            enabled: true,
            y: 20,
            style: {
              color: this.darkMode ? '#ffffff':'#212121',
              textOutline:'0'
            }
          }
        };
        this.option.series[0].data[maxIndex] = maxDataObject;
        this.option.series[0].data[minIndex] = minDataObject;
        // this.option.series[1].data = [
        //   {x: maxRenkoData[0], title: maxRenkoData[2], fillColor: '#EEEEEE'},
        //   {x: minRenkoData[0], title: minRenkoData[3], fillColor: '#EEEEEE'}
        // ];
      },
      // 生成图片
      generateImage() {
        // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
        html2canvas(this.$refs.renkoImage, {
          backgroundColor: this.darkMode ? '#303030' : '#EEEEEE'
        }).then((canvas) => {
          let url = canvas.toDataURL('image/png');
          this.imageBase64Url = url;
          this.uploadBase64Loading = true;
          this.renkoImageDialog = true;
          // 把生成的base64位图片上传到服务器,生成在线图片地址
          this.uploadBase64();
        })
  　　},
      // 点击下载图片
      saveImage(){
        let a = document.createElement('a');
        // 设置图片地址
        a.href = this.imageBase64Url;
        // 设定下载名称
        a.download = 'Renko-Data-' + this.strategy + '-' + this.renkoSymbol;
        // 点击触发下载
        a.click();
      },
      // 删上传base64
      uploadBase64(){
        this.uploadBase64Loading = true;
        let api = config.baseUrl + '/upload/base64';
        let param = { base64: this.imageBase64Url };
        this.$http.post(api, param).then(response => {
          let data = response.data;
          this.uploadBase64Loading = false;
          if (data.code == 200) {
            this.imageHttpUrl = data.result;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.uploadBase64Loading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 复制到剪切板
      copyToClipboard(id) {
        let target = document.querySelector('#' + id);
        try {
          let range = document.createRange();
          // 选择对象
          range.selectNode(target);
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
          // 执行浏览器复制命令
          document.execCommand('copy');
          window.getSelection().removeAllRanges();
          // 复制成功
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
        } catch (e) {
          // 复制失败
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
        }
      },
      // 复制成功回调函数
      copySuccess(){
        this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
      },
      // 复制失败回调函数
      copyError(){
        this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
      },
      //全屏
      toggleFullscreen() {
        if (this.chart.fullscreen) {
          this.chart.fullscreen.toggle();
        }
      },
      // 开始交易
      startTrading(category){
        let query = {
          side: this.orderParam.side,
          strategy: this.orderParam.strategy,
          price: this.orderParam.price
        };
        if (category == 'futures') {
          this.$router.push({
            name: 'futuresTrading',
            params: {
              exchangeCode: this.defaultExchange,
              symbol: 'PI_' + this.currency + 'USD'
            },
            query: query
          });
        } else if (category == 'swapUsdt') {
          this.$router.push({
            name: 'swapUsdtTrading',
            params: {
              exchangeCode: this.defaultExchange,
              symbol: this.currency + '-USDT-SWAP'
            },
            query: query
          });
        }
      },
      // 生成范围选择器的按钮
      getRangeSelectorButtons(){
        let len = this.seriesData.length;
        let timestamp = 0;
        // 最后的时间戳
        let lastTimestamp = this.seriesData[len - 1][0];
        if (len > 60) {
          // 取出倒数第40个块的时间
          timestamp = this.seriesData[len - 60][0];
        } else {
          // 取出第1个块的时间
          timestamp = this.seriesData[0][0];
        }
        // 距离现在的时间差
        let timestampDifference = lastTimestamp - timestamp;
        // 时间差的小时
        let hours = Math.ceil(timestampDifference / 3600000);
        let buttons = [
            { type: 'hour', count: hours, text: this.$t('common.auto') },
            { type: 'all', text: this.$t('common.all') }
          ];
        this.option.rangeSelector.buttons = buttons;
        this.chart = Highcharts.stockChart(this.id, this.option);
      },
    }
  }
</script>