<template>
	<div class="ma-2">
        <renko-market-price :symbol="currency + '-USDT'" @emitCurrentPrice="receiveCurrentPrice"></renko-market-price>
        <v-row class="mt-12">
            <v-col cols="4" lg="1" md="2" class="ml-1">
                <v-select v-model="currency" :items="currencies" dense flat solo outlined hide-details :label="$t('strategy.currency')"></v-select>
            </v-col>
            <v-col cols="6" md="2" class="align-self-sm-center">
                <div class="ml-5">
                    <span class="fs-16">{{ $t('signals.currentPrice') + ': '}}</span>
                    <span class="fs-24" :class="currentPriceColor">{{ currentPrice > 0 ? currentPrice : '--' }}</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="mx-1">
            <v-col cols="12" md="6" xs="6" class="pa-0" v-for="(renkoConfig, index) in renkoConfigs" :key="index">
                <!-- id加上index是为了不和图表的id重复 -->
                <div class="border-shortdash" :id="index + '-' + renkoConfig.strategyName">
                    <strategy-renko-chart class="my-4" :currency="currency" :strategy="renkoConfig.strategyName" tradingCategory="swap-usdt"></strategy-renko-chart>
                </div>
            </v-col>
            <v-col cols="12" md="6" xs="6" class="align-self-sm-center text-center pa-0">
                <div class="border-shortdash align-items-center" :style="{height: blockHeight, width: blockWidth}">
                    <v-row class="justify-center">
                        <v-col cols="8" md="4" class="justify-center">
                            <div class="border-shortdash pointer" :title="$t('strategy.new')" @click="gotoStrategy">
                                <v-icon size="48" class="ma-16" color="primary">mdi-plus</v-icon>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    <mobile-btn></mobile-btn> 
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import RenkoMarketPrice from '@/components/marketPrice/RenkoMarketPrice';
    import StrategyRenkoChart from '@/highcharts/StrategyRenkoChart';
    import MobileBtn from "@/components/common/MobileBtn";
    export default {
        data(){
            return {
                // 当前交易对
                currency: this.$route.params.currency.toUpperCase(),
                // 配置集合
                renkoConfigs: [],
                // 新增区块默认高度
                blockHeight: '419px',
                // 新增区块默认宽度
                blockWidth: '850px',
                // 上一次价格
                lastFillPrice: 0,
                // 当前价
                currentPrice: 0,
                // 当前价格颜色
                currentPriceColor: ''
            }
        },
        components: { RenkoMarketPrice, StrategyRenkoChart,MobileBtn },
        created(){

        },
        mounted(){
            this.getRenkoConfigs();
        },
        computed: {
            ...mapGetters(['responsive', 'currencies']),
        },
        watch:{
            currency(newVal, oldVal){
                this.lastFillPrice = 0;
                this.currentPrice = 0;
                this.currentPriceColor = '';
                this.$router.push({ name: "renkoCharts", params: { currency: this.currency } });
                this.getRenkoConfigs();
            },
            $route() {
                if (this.$route.params.currency) {
                    this.currency = this.$route.params.currency;
                }
            },
        },
        methods: {
            // 查询Renko配置
            getRenkoConfigs(){
                if (this.currency == null) {
                    return;
                }
                let api = config.baseUrl + '/renko/config/private/configs/currency?currency=' + this.currency;
                this.renkoConfigs = [];
                this.$http.get(api).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        this.renkoConfigs = data.result.reverse();
                        this.$forceUpdate();
                    } else if (data.code == 204) {
                        this.renkoConfigs = [];
                    } else {
                        this.renkoConfigs = [];
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                    this.getBlockStyle();
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 跳转到策略
            gotoStrategy(){
                this.$router.push({ name: "strategy" });
            },
            // 动态获取块的宽和高
            getBlockStyle(){
                if (this.renkoConfigs.length > 0) {
                    let id = '0-' + this.renkoConfigs[0].strategyName;
                    let _this = this;
                    setTimeout(function () {
                        let el = document.getElementById(id);
                        _this.blockHeight = (el.clientHeight + 2) + 'px';
                        _this.blockWidth = (el.clientWidth + 2) + 'px';
                    }, 100);
                } else {
                    if (this.responsive == 'PC') {
                        this.blockHeight = '419px';
                        this.blockWidth = '850px';
                    } else {
                        this.blockHeight = '416px';
                        this.blockWidth = '351px';
                    }
                }
            },
            // 接受当前价格
            receiveCurrentPrice(currentPrice){
                this.currentPrice = currentPrice;
                // 判断颜色，相等不变色
                if (this.currentPrice > this.lastFillPrice) {
                    this.currentPriceColor = 'green--text';
                } else if (this.currentPrice < this.lastFillPrice) {
                    this.currentPriceColor = 'red--text';
                }
                // 最后将本次价格更新为上一次价格
                this.lastFillPrice = this.currentPrice;
            }
        }
    }
</script>
