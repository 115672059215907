<template>
  <div>

  </div>
</template>
<style>

</style>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import Bus from "@/components/bus/Bus";
    export default {
        data(){
            return{
              tickerTimer: null,
              api: config.baseUrl + '/exchange/okex/spot/public/ticker/'
            }
        },
        created(){

        },
        mounted(){
          this.getTicker();
          this.tickerTimer = setInterval(() => {
            this.getTicker();
          }, 8000);
        },
        computed: {
            ...mapGetters(['networkStatus'])
        },
        props: [ 'symbol' ],
        watch:{
          symbol(newVal, oldVal){
            this.getTicker();
          }
        },
        methods: {
          // 获取Spot市场价
          getTicker(){
            // 未联网时不发送请求
            if (!this.networkStatus) {
              return;
            }
            // 没有交易对不发请求
            if (this.symbol == null) {
              return;
            }
            if(this.$route.path.indexOf('/strategy/charts/renko') > -1 && this.api != null){
              this.$http.get(this.api + this.symbol).then(res => {
                  let data = res.data;
                  if (data.code > 0) {
                    // 错误的请求
                    return;
                  }
                  let currentPrice = Number(data.last);
                  // 传值给父组件显示
                  this.$emit('emitCurrentPrice', currentPrice);
                  // 传值给Renko组件计算砖块
                  Bus.$emit('emitCurrentPrice', currentPrice);
                }, error => {

                })
              }
            }
        },
        beforeDestroy() {
          if(this.tickerTimer) {
            clearInterval(this.tickerTimer);
          }
        },

    }
</script>